<template>
  <div style="border-bottom: 1px solid #dfdfdf;font-size: 14px;">
    <div style="display: flex;height: 40px;padding: 0 200px;background-color: #19b4ea;">
      <div class="top-title" style="padding-right: 20px;border-right: 1px solid rgb(230 230 230 / 40%);line-height: 40px;color: #FFFFFF;">手机版</div>
      <div class="top-title" style="padding-right: 20px;border-right: 1px solid rgb(230 230 230 / 40%);line-height: 40px;color: #FFFFFF;margin-left: 20px;">留言反馈</div>
      <div class="top-title" style="padding-right: 20px;border-right: 1px solid rgb(230 230 230 / 40%);line-height: 40px;color: #FFFFFF;margin-left: 20px;">会员中心</div>
      <div class="top-title" style="line-height: 40px;color: #FFFFFF;margin-left: 20px;">收藏本站</div>
      <div style="flex: 1;display: flex;">
        <div style="flex: 1;"></div>
        <div style="width: 170px;display: flex;">
          <el-button type="text" style="color: #FFFFFF;" class="top-title">登录</el-button>
          <el-button type="text" style="color: #FFFFFF;margin-right: 10px;" class="top-title">注册</el-button>
          <div style="background-color: rgb(223 221 221 / 40%);display: flex;width: 100px;padding: 0 10px;" class="top-title">

            <el-button type="text" style="color: #FFFFFF;" class="top-title">购物车</el-button>
          </div>
        </div>

      </div>
    </div>
    <div style="margin: 0 200px;height: 80px;line-height: 80px;">
      <div style="display: flex;">
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;margin-left: 30px;">首页</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">电子数码</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">家居百货</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">母婴用品</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">关于我们</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">联系我们</div>
          <div style="flex: 1;text-align: right;">
            <el-input placeholder="请输入内容" v-model="input" style="width: 300px;">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
          </div>
      </div>
    </div>
  </div>
</template>ge

<script>
  export default {
      data() {
        return {
           input:''
        };
      },
      mounted() {
      },
      methods: {
         homePage(){
           this.$router.push({path: '/home/index'});
         },
      }
    };
</script>

<style>
  .top-title:hover{
    cursor: pointer;
    color: #e5e5e5!important;
  }
  .title{
    font-weight: bold;
    font-size: 18px;
  }
  .title:hover{
    color: #c250c6!important;
  }
</style>