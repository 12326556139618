<template>
  <div style="border-bottom: 1px solid #dfdfdf;font-size: 14px;">

    <div style="margin: 0 200px;height: 80px;line-height: 80px;">
      <div style="display: flex;">
            <img src="../../assets/logo.png" style="cursor: pointer;height: 60px;margin-top: 10px;">
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;margin-left: 30px;">首页</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">关于我们</div>
          <div class="title" style="margin-right: 20px;cursor: pointer;color: gray;">联系我们</div>
      </div>
    </div>
  </div>
</template>ge

<script>
  export default {
      data() {
        return {
           input:''
        };
      },
      mounted() {
      },
      methods: {
         homePage(){
           this.$router.push({path: '/home/index'});
         },
      }
    };
</script>

<style>
  .top-title:hover{
    cursor: pointer;
    color: #e5e5e5!important;
  }
  .title{
    font-weight: bold;
    font-size: 18px;
  }
  .title:hover{
    color: #c250c6!important;
  }
</style>